import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const GetStartedPage = () => (
  <Layout>
    <SEO title="Get Started" />
    <div className="container page pt-16 pb-20 lg:max-w-lg body-content">
      <h1 className="hero-title mb-8 md:mb-12 lg:text-center">
        Let's Get Started!
      </h1>
      <p>
        Send me an email first to introduce yourself and tell me about your
        project at <a href="mailto:bill@thedream.shop">bill@thedream.shop</a>.
        Including photos is a great idea. I'll review and reply with my
        thoughts. To get an idea of cost, you might want to look at the 
        <Link to="/pricing">Pricing Estimator</Link> page. If your project
        involves removal of old lettering, I can’t give you a final price until
        I have the jersey in hand and can see what is involved. Sometimes
        lettering comes right off, other times it is a lot of work
      </p>
      <p>
        If a phone conversation is productive we can schedule one after we’ve
        corresponded.  If you prefer, you can call me at{' '}
        <a href="tel:+4843262801">(484) 326-2801</a> and leave a message if I am
        busy.
      </p>
      <p>
        Please send me clean jerseys to letter. I use heat in my process, and
        dirty jerseys placed in the heat-press will seal in dirt and smells
        forever. If I have to launder your jersey before lettering I charge an
        additional $10.
      </p>
      <p>
        When you send your jerseys to me, please include contact information
        inside the box. I need a return mailing address. I will bill you after I
        have looked over the jersey(s) and before I begin work.{' '}
      </p>
      <p>
        I recommend that you ship your packages <em>insured</em>.
      </p>
      <p>My address is:</p>
      <p>
        <address className="ml-4">
          <b>The Dream Shop</b>
          <br />
          P.O. Box 63
          <br />
          Gradyville, PA 19039
          <br />
          <a href="tel:+4843262801">(484) 326-2801</a>
        </address>
      </p>
      <p>For UPS and Federal Express:</p>
      <p>
        <address className="ml-4">
          <b>The Dream Shop</b>
          <br />
          1080 Forest Lane
          <br />
          Glen Mills, PA 19342
        </address>
      </p>
      <p>
        When I ship back to you, I also ship insured, and if the value is over
        $350 or so, the Post Office may ask for a signature upon delivery. Keep
        this in mind, especially if the postman delivers mail to your home when
        you are not there.
      </p>
    </div>
  </Layout>
);

export default GetStartedPage;
